<template>
  <div id="nav">
    <router-link :to="{ path : '/login', query : { 'code' : jscode } }">查看工资</router-link> |
    <router-link :to="{ path : '/signup', query : { 'code' : jscode } }">注册密码</router-link>
  </div>
  <router-view/>
</template>

<script>
import { jscodeKey } from '../utils/constants'
import { getQueryVariable } from '../utils/urlUtils'
export default {
  name : 'LoginPanel',
  data : function() {
    return {
      jscode : ''
    }
  },

  created() {
    let code = getQueryVariable(jscodeKey)
    if (code != null) {
      this.jscode = code
      this.$router.replace({ path : '/login', query : { 'code' : code } })
    } else {
      // 出错, 没在url中找到‘code’
      this.jscode = ''
      this.$router.replace('/error?reason=' + '未获得微信授权，不能登陆')
    }
  }
}
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>